import React from 'react';
import {
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, ActionBar, Icon } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../data/snippets/action-bar.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, ActionBar: ActionBar, Icon: Icon }}
        code={snippet}
        platform="react"
        gitHubLink="action-bar"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="isOpen" types={['bool']}>
            <Text>Determines whether the action bar is visible.</Text>
          </PropListItem>

          <PropListItem name="infoText" types={['node']}>
            <Text>
              The text that appears on the left side. Defaults to display as
              bold.
            </Text>
          </PropListItem>

          <PropListItem name="actions" types={['arrayOf', 'action']}>
            <Text>
              One or more buttons to be displayed on the right side. Each action
              has the following shape:
            </Text>
            <List type="unordered">
              <li>
                <code>label</code> (string) - the text describing the action
              </li>
              <li>
                <code>icon</code> (element or string) - an SVG element or the
                name of a Uniform icon
              </li>
              <li>
                <code>disabled</code> (bool) - determines whether the action is
                disabled
              </li>
              <li>
                <code>qaId</code> (string) - a unique string to control this
                action in automated tests
              </li>
              <li>
                <code>actionRef</code> (func) - a func to assign a ref to the
                button
              </li>
              <li>
                <code>onClick</code> (func) - responds to the action being
                clicked
              </li>
            </List>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this actionbar in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="onClose" types={['func']}>
            <Text>
              Function called when the X is clicked to close the action bar.
              Update the <code>isOpen</code> value to false.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            The action bar is positioned via <code>sticky</code>. That means it
            will take up space in the flow of the document until the user
            scrolls down, at which point it will become fixed. If you don't want
            the empty space taken up by the action bar, you'll need to give your
            container a negative margin.
          </li>
          <li>
            The actions have a <code>className</code> prop in case you need to
            hide the label or icon at smaller sizes.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
